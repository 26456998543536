import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AnalysisKey, RDSAnalysisData, SearchResult } from '../../../model/analysis-details.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AuthNewService } from '../../../service/auth-new.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-show-table',
  templateUrl: './show-table.component.html',
  styleUrls: ['./show-table.component.scss']
})
export class ShowTableComponent implements OnInit {

  @ViewChild('RApaginator', { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<RDSAnalysisData>;
  tableColumns: string[] = ['analyteName', 'analysisType', 'analysisDate', 'projectNames'];

  reportAnalysis: RDSAnalysisData[] = [];
  @Input() searchResults: RDSAnalysisData[] = [];
  count: number;

  @ViewChild('htmlData1') htmlData1!: ElementRef;
  @ViewChild('htmlData2') htmlData2!: ElementRef;
  @ViewChild('htmlData3') htmlData3!: ElementRef;
  private currentLoginUser: Subscription;
  userAttributes
  signedInUser
  illegalUser:boolean

  constructor() { }



  public openPDF1(): void {
    const DATA: any = document.getElementById('htmlData1');
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');

      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('report-analysis.pdf');
    });
  }


  ngOnInit() {

        this.dataSource = new MatTableDataSource(this.searchResults);

        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'analysisDate': return new Date(item.analysis_date);
            // case 'analysisDate': return item.analysisDate;
            case 'projectNames': return item.project_code;
            case 'analyteNames': return item.file_name;
            default: return item[property];
          }
        }
        setTimeout(() => this.dataSource.sort = this.sort);

        for (const result of this.searchResults) {

          this.reportAnalysis.push(result);

        }
        this.dataSource = new MatTableDataSource(this.reportAnalysis);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        setTimeout(() => { this.dataSource.sort = this.sort });
  }

  getUrl(analysisType: string) {

    if (analysisType === 'SMR' || analysisType === 'LMR' || analysisType === 'ADA') {
      return '/default-layout/molecule-analysis';
    } else if (analysisType === 'qPCR') {
      return '/default-layout/qpcr-molecule-analysis';
    }
    else {
      return '/default-layout/notfound';
    }

  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
