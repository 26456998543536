import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
// import Highcharts from 'highcharts';
// import Bellcurve from 'highcharts/modules/histogram-bellcurve';
// import Histogram from 'highcharts/modules/histogram-bellcurve';

// Bellcurve(Highcharts);

// Histogram(Highcharts);

// const Exporting = require('highcharts/modules/exporting');
// Exporting(Highcharts);

// const ExportData = require('highcharts/modules/export-data');
// ExportData(Highcharts);

// const Accessibility = require('highcharts/modules/accessibility');
// Accessibility(Highcharts);

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {

    name = 'XYZ';

    activity;
    xData;
    label;
    options: any;

    chart_data: Array<number> = [-5.85, -6.54, -5.52, 7.73, 10.06, 13.89, 2.78, 4.42, 2.53, 3.18, -1.92, 17.42, 12.64,
        7.27, 9.76, 3.57, 0.63, 1.24, 4.61, 1.62, 1.78, -2.1, -2.68, -5.67, -10.76, -1.75, 5.49, 3.21,
        -15.07, 2.94, -9.74, 12.43, 5.59, 6.94, 1.6, 0.68, -8.18, -1.29, 0.62, -2.92, 1.12, 7.53, 5.14, 3.17,
        18.95, 12.75, 11.32, 21.11, 14.43];



    constructor(private dialogRef: MatDialogRef<GraphComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.name = data.name;
    }

    @HostListener('document:keyup.escape') onClose() {
        this.onCancel();
    }

    onCancel() {
        this.dialogRef.close();
    }

    ngOnInit(): void {
    }

    // onSubmit() {
    //   this.dialogRef.close(this.food);
    // }

}
