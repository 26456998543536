import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SignupForm } from '../../types';
import {AuthService} from '../../../../service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-password',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class FirstTimePasswordComponent {
  newPassword: string;
  confirmPassword: string;
  submissionError: string;
  statusMessage: string;
  submitted = false;
  statusCode: string;
  success: boolean = false;
  firstTimeForm: FormGroup;
  hide: boolean = true;
  isValid: boolean;

  constructor(private authService: AuthService, private router: Router, public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.firstTimeForm  =  this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
  });
}

get formControls() { return this.firstTimeForm.controls; }

  private validateNewPassword() {
    this.isValid = true;
    this.newPassword = this.firstTimeForm.value.password;
    this.confirmPassword = this.firstTimeForm.value.confirmPassword;
    if (this.confirmPassword !== this.newPassword) {
      this.isValid = false;
      this.submissionError = 'Confirm password should match new password.';
    }

    return this.isValid;
  }

  updatePassword() {}

  // updatePassword() {
  //   if (!this.validateNewPassword()) {
  //     return;
  //   }
  //   this.submitted = true;
  //   this.newPassword = this.firstTimeForm.value.password;
  //   this.authService.authenticate({
  //     newPassword: this.newPassword
  //   },
  //     (err, statusCode) => {
  //       this.submitted = false;
  //       if (statusCode === AuthService.statusCodes.signedIn) {
  //         this.success = true;
  //         this.statusCode = statusCode;
  //         this.statusMessage = 'Password change is successful. You will be redirected to signin page within 5 seconds';
  //         setTimeout(() => { this.authService.signout(); }, 5000);
  //         return;
  //       } else if (statusCode === AuthService.statusCodes.incompletedSigninData) {
  //         this.success = false;
  //         this.statusCode = statusCode;
  //         this.router.navigate(['']);
  //         return;
  //       } else if (statusCode === AuthService.statusCodes.unknownError) {
  //         this.success = false;
  //         this.statusCode = statusCode;
  //         this.submissionError = err.message;
  //       }
  //     });
  // }
}
