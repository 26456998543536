import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy, NgZone } from '@angular/core';
import { SharedService } from '../shared-service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { Subscription } from 'rxjs-compat';

@Component({
  moduleId: module.id,
  // tslint:disable-next-line: component-selector
  selector: 'default-layout',
  templateUrl: 'default.component.html',
  styleUrls: ['default.component.scss'],
  providers: [ SharedService ]
})
export class DefaultLayoutComponent implements OnInit,OnDestroy {
  pageTitle: any;
  rtl: boolean;
  @Input() openedSidebar: boolean;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  private idleOnTimeout: Subscription;
  private idleonIdleStart: Subscription;
  private idleonIdleEnd: Subscription;

  @ViewChild('childModal') childModal: ModalDirective;

  constructor(private _sharedService: SharedService,
              private idle: Idle,
              private keepalive: Keepalive,
              private router: Router,
              private authService: AuthService,
              private ngZone:NgZone,
              cd: ChangeDetectorRef
              ) {
             
    this.rtl = false;
    this.openedSidebar = true;
    _sharedService.changeEmitted$.subscribe(
      title => {
        this.pageTitle = title;
        this.openedSidebar = true;
      }
    );

    idle.setIdle(1200);
    idle.setTimeout(45);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    

    this.idleonIdleEnd=idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';  
   });

 this.idleonIdleStart= this.idle.onIdleStart.subscribe(() => {
    this.idleState = 'You\'ve gone idle!';
    this.timedOut = true;
    
    this.childModal.show();
});


 this.idleOnTimeout= idle.onTimeout.subscribe(() => {
    this.childModal.hide();
    this.idleState = 'Timed out!';
    this.timedOut = false;
    this.router.navigate(['/extra-layout/signout']);
    this.ngZone.run(()=>this.router.navigate(['/extra-layout/signout']))

  });
 
   
    
  idle.onTimeoutWarning.subscribe((countdown) => {
    // this.idleState = 'You will time out in ' + countdown + ' seconds!'
    this.idleState = "You will automatically logged out in " + countdown + " seconds due to inactivity"
  });

  // sets the ping interval to 15 seconds
  keepalive.interval(15);

  keepalive.onPing.subscribe(() => this.lastPing = new Date());
  


  }

  ngOnInit() {
    this.reset();
    this.authService.getUserLoggedIn().subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        this.idle.watch();
        
      } else {
        this.idle.stop();
       
      }
    })
 
  }

  getClasses() {
    return {
      'open-sidebar': this.openedSidebar,
      'rtl': this.rtl
    };
  }

  sidebarState() {
    this.openedSidebar = !this.openedSidebar;
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
  }

  logout() {
    this.childModal.hide();
    this.idle.stop();
    this.authService.setUserLoggedIn(false);
    this.router.navigate(['extra-layout/signout']);
    this.ngZone.run(()=>this.router.navigate(['/extra-layout/signout']));
   
  }


  ngOnDestroy(){
    this.idleonIdleStart.unsubscribe();
    this.idleOnTimeout.unsubscribe();
    this.idleonIdleEnd.unsubscribe();
   
  }
 
}
