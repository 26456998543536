import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Subject} from 'rxjs';
import { RTDashBoard } from '../model/dashboard.model';


@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  dashboard_URL: string = environment.serverUrl + '/dash_data';
  dash_board = new Subject<RTDashBoard[]>();


  constructor(private http: HttpClient, private authService: AuthService) { }

   // HttpClient API get() method
  getData(userId) {
    let params = new HttpParams();
    params = params.append('userId', userId);

    this.http.get(this.dashboard_URL, { params: params })
      .map(res => res = <RTDashBoard[]>res)
      .subscribe((data) => {
        this.dash_board.next(data);
        sessionStorage.setItem('xyz', JSON.stringify(data));
      },
       )
  }


}
