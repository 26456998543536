import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AnalysisKey, AuthLog, RDSAnalysisData } from '../../../model/analysis-details.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { DBService } from '../../../service/DB.service';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';
import { User } from '../../../model/types';
import { bool } from 'aws-sdk/clients/signer';
import { AuthNewService } from '../../../service/auth-new.service';
import {delay} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../service/storage.service';

@Component({
  selector: 'app-auth-log',
  templateUrl: './auth-log.component.html',
  styleUrls: ['./auth-log.component.scss']
})

export class AuthLogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<AuthLog>;
  tableColumns: string[] = ['userId', 'activityDate', 'eventResponse'];

  @ViewChild(MatPaginator, { static: false }) ch_paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) ch_sort: MatSort;
  logdata: AuthLog[] = [];
  temp_analysisData: any;

  authLogs: AuthLog[] = [];
  // role = sessionStorage.getItem('access');
  signedInUser: any;
  loading = false;
  LogstartDate;
  LogendDate;
  userID: string;
  showTable: boolean = false
  userAttributes
  illegalUser: boolean;
  private authlogDataSubscription: Subscription;
  private currentLoginUser: Subscription;
  maxDate = new Date();



  constructor(
    private dbService: DBService,
    private authService: AuthNewService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private storageService:StorageService
  ) {

  }

  ngOnInit() {
    this.authService.getCurrentUser();
    this.userAttributes= this.storageService.get('access')
    this.currentLoginUser = this.authService.getCurrentLoginUser.subscribe((user: any) => {
      this.signedInUser = user
      // this.userAttributes = user.attributes;
      if (!this.signedInUser) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      }
      else if (this.userAttributes === 'PI'|| this.userAttributes === 'QA') {
        this.illegalUser = true;
      }
      else if (this.userAttributes === 'GA' || this.userAttributes === 'SU') {
        this.illegalUser = false;
        this.dbService.getAuthData(this.signedInUser.username, this.signedInUser.username, this.LogstartDate, this.LogendDate, this.userAttributes)


        this.authlogDataSubscription = this.dbService.authLogs.subscribe(
          (data:  AuthLog[]) => {
            if (data && data.length) {
              this.showTable = true
              this.dataSource = new MatTableDataSource(data);
              this.ref.detectChanges();
              this.dataSource.paginator = this.paginator;
            }
            else {
              this.showTable = false;
            }
          }
        );

      }
    });


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  openPDF() {
    const DATA: any = document.getElementById('authData');
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Login-Report.pdf');
    });
  }




  passSelectedDates() {
    this.logdata = [];
    const data = this.dbService.getAuthData(this.storageService.get('username'), this.userID, this.LogstartDate, this.LogendDate, this.userAttributes);


  }

  selectedLogDates(dateRangeStart, dateRangeEnd, UserID) {
    this.LogstartDate = dateRangeStart.value;
    this.LogendDate = dateRangeEnd.value;
    this.userID = UserID.value;
    this.passSelectedDates();

  }

  ngOnDestroy() {
    // Unsubscribe from the observable to avoid memory leaks
    if (this.authlogDataSubscription) {
      this.authlogDataSubscription.unsubscribe();
    }
    if (this.currentLoginUser) {
      this.currentLoginUser.unsubscribe();
    }
  }

}
