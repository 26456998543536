import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { StorageService } from '../../service/storage.service';


@Injectable({
    providedIn: 'root'
})
export class MainMenuService {
    constructor(private http: HttpClient,
        private storageService:StorageService) {
    }

    public getData(userid: Object) {
       
     const Authorization= this.storageService.get('Authorization');
     if(Authorization == 'false'){
console.log("Unauthorized user :: Cannot view side-panel")
     }
     else{
        let URL = 'assets/data/main-menu.json';
        const access= this.storageService.get('access')

        if (access === 'SU'|| access === 'GA') {
           URL = 'assets/data/admin-main-menu.json';
        }
        else if(access === 'QA'){
         URL = 'assets/data/QA-menu.json';
        }
        else
        {
         URL = 'assets/data/main-menu.json';
        }
        return this.http.get(URL);
     }
     
    }

    public handleError(error: any) {
        return observableThrowError(error.error || 'Server Error');
    }
}
