import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Chart, ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {AnalysisSummary, Summary} from '../../../model/analysis-details.model';
import {BaseChartDirective, Color} from 'ng2-charts';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {RTDashBoard} from '../../../model/dashboard.model';
import { AuthService, DashboardService } from '../../../service';
import { User } from '../../../model/types';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { SharedService } from '../../../layouts/shared-service';
import {DialogComponent} from '../dialog/dialog.component';
// import { objectEach } from 'highcharts';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],

})
export class ChartsComponent implements OnInit {

  // dialogRef: MatDialogRef<DialogResultComponent>;
  barChartMessage = 'Bar Chart ';
  lineChartMessage = 'Line Chart ';
  // @Input() summary: Summary;
  // @Input() analysisSummary: AnalysisSummary[];
  // public searchResults: SearchResult[] = [];
  // @Input() dateArray: [];
  // @Input() countArray: [];

  @ViewChild('reportsAnalyzedChart', {static: false}) reportsAnalyzedChart: ElementRef;
  @ViewChild('analyte1PieChart', {static: false}) analyte1PieChart: ElementRef;
  @ViewChild('analyte2PieChart', {static: false}) analyte2PieChart: ElementRef;
  @ViewChild('analyte3PieChart', {static: false}) analyte3PieChart: ElementRef;

  @ViewChild(BaseChartDirective, {static: false})
  public chart: BaseChartDirective;


  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  @Input() dashboard: RTDashBoard[] = [];

  lineCharts: RTDashBoard[] = [];
  lineChart: RTDashBoard;
  horizontalCharts: RTDashBoard[] = [];
  adaData: any[] = [];
  adaDate;
  resultItem: any[] = [];
  tableData
  adaFlag: boolean;
  horizontalChart: RTDashBoard;

  lineChartOptions = {
    responsive: true,
    // scales: { xAxes: [{}], yAxes: [{
    //   position: 'left',
    // }]
    // }
  };
  lineChartLabels = [];
  barChartLabels = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  // barChartPlugins = [pluginDataLabels];

  showModal: boolean;

  public barChartData: ChartDataSets[] = [];
  public chartColors: Color[] = [
    { backgroundColor: '#113f59'},
    { backgroundColor: '#0a827c'},
    { backgroundColor: '#43bd9f'},
    { backgroundColor: '#5be8a9'}

    // { backgroundColor: '#7b60ff'},
    // { backgroundColor: '#7f76ff'},
    // { backgroundColor: '#a77dff'},
    // { backgroundColor: '#db91ff'}


  ];
  public processing_colors: Color[] = [
    { backgroundColor: 'rgba(206,76,60,0.51)',
      borderColor: 'rgb(255,88,61)'},
    { backgroundColor: 'rgba(206,206,61,0.67)',
      borderColor: 'rgba(206,206,61,0.99)'},
    { backgroundColor: 'rgba(29,159,26,0.47)',
      borderColor: 'rgb(29,159,26)'}

  ];

  public hozBarChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public hozBarChartType: ChartType = 'horizontalBar';

  _signedInUser;
  chartReady = false;
  isAvailabel = false;

  constructor(private authService: AuthService, private dashboardService: DashboardService, public dialog: MatDialog) {
    this.authService.getCurrentUser((err, user: User) => {
      this._signedInUser = user;
      if (!this._signedInUser || !this._signedInUser.signedIn) {
        // this.router.navigate(['/extra-layout/signin']);
        return;
      } else {
        this.dashboardService.getData(this._signedInUser.username);
      }
    });
  }


  ngOnInit() {
    for (const obj of this.dashboard) {
      if (obj.ChartType === 'bar') {
        this.barChartData = obj.ChartData;
        this.barChartLabels = obj.ChartLabels;
      } else if (obj.ChartType === 'line') {
        this.lineCharts.push(obj);
      } else if (obj.ChartType === 'horizontalBar') {
        this.horizontalCharts.push(obj);
      } else if (obj.AdaChart === 'AdaChart') {
        this.adaData.push(obj);
      }
    }
    const date = this.barChartLabels[this.barChartLabels.length - 1];

    this.lineChart = this.lineCharts.slice(-1)[0];
    this.horizontalChart = this.horizontalCharts.slice(-1)[0];
    this.updateAdaTable(date);
   }


  onBarChartClick(e) {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if ( activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        this.updateLineChart(label);
        this.updateAdaTable(label);
      }
    }
  }

  updateLineChart(label: string) {
    for (const obj of this.lineCharts) {
      if (obj.ChartId === label) {
        this.lineChart = obj;
      }
    }
  }

  updateAdaTable(label) {
    for (const data of this.adaData) {
      if (data.ChartId === label) {
        this.adaDate = data.ChartId;
        for (const tableData of data.AdaTable) {
          if (tableData) {
            this.adaFlag = false
          }
          for (const i of tableData) {
            for (const rItem of i.resultItem) {
              for (const item of rItem.item) {
                this.tableData = item.message;
              }
            }
          }
        }
      }
    }
  }

  onLineChartClick(e) {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if ( activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        this.updateHorizonalChart(label);
      }
    }

  }

  updateHorizonalChart(label: string) {
    for (const obj of this.horizontalCharts) {
      if (obj.ChartId === label) {
        this.horizontalChart = obj;
      }
    }
  }

  getUrl(analysisType: string) {
    if (analysisType === 'Small Molecule' || analysisType === 'Large Molecule' || analysisType === 'ADA') {
      return '/default-layout/molecule-analysis';
    } else {
      return '/default-layout/notfound';
    }
  }

  openDialog(data) {
    this.dialog.open(DialogComponent, {data: {name: data}, disableClose: false});
   }

}



