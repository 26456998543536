import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Rx';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, DBService, DownLoadService } from '../../service';
import { Result, Table, outputGraph } from '../../model/small-molecule.model';
import { AnalysisDetails, NewTable, SearchResult } from '../../model/analysis-details.model';
// import { NgxXml2jsonService } from 'ngx-xml2json';
import { SharedService } from '../../layouts/shared-service';
import { User } from '../../model/types';
import { DropDownService } from '../../service/drop-down.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
// import { GraphDialogComponent } from './graph-dialog/graph-dialog.component';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';
import { interval } from 'rxjs/internal/observable/interval';
import { error } from 'console';
import { bool } from 'aws-sdk/clients/signer';
import { AuthNewService } from '../../service/auth-new.service';
import { delay } from 'rxjs/operators';
import { StorageService } from '../../service/storage.service';

@Component({
    selector: 'app-small-molecule',
    templateUrl: './small-molecule.component.html',
    styleUrls: ['./small-molecule.component.scss']
})
export class SmallMoleculeComponent implements OnInit, OnDestroy {

    pageTitle = 'Analyze Report/Data';
    signedInUser: any;
    analysisId = '';
    searchResult: SearchResult = <SearchResult>{};
    // analysisResponseNew: SearchResult = <SearchResult>{};
    selectedTables: string;
    analysisDetails: AnalysisDetails;
    public tables: Table[] = [];
    tablesKey: any = [];
    tablesValue: any = [];
    stattablesKey: any = [];
    stattablesValue: any = [];
    tablesList: NewTable[];
    tablesNotFound: any = [];
    tableData: any;
    tableAnalysisType: string;
    routersub: ISubscription;
    callerSubscription: Subscription;
    tableSelected = false;
    tableProcessed = false;
    reviewTableSelection = false;
    public results: Result[];
    tempData: any;
    title = 'Loading';
    mysubscription: any;
    visibleData = 0;
    public showcharts = false;
    item;
    output_graph: outputGraph[];
    graphs: any[] = [];
    // public Histograms = [];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    // @ViewChild('textData') data: ElementRef;

    step = 0;
    updateOptions: Object;
    data: any;
    options: {};
    navigationSubscription: Subscription;
    usr: any;
    formType: any;
    currentUser: User;
    illegalUser: boolean;
    sData: SearchResult;
    checked: boolean = false;
    disabled: boolean = false;
    displayTable: boolean = false;
    isFetching: boolean = true;
    displayPDFData: boolean = true;
    displayExPanels: boolean = true;
    searchResultData: boolean = true;
    errortable: any;
    extractrunid = [];
    columnArray = [];
    dataArray = [];
    jsonDataArray = [];
    displayObservation: boolean = false;
    signedInUserAccess

    constructor(private _sharedService: SharedService,
        private activatedroute: ActivatedRoute,
        private authService: AuthService,
        private AuthService: AuthNewService,
        private elRef: ElementRef,
        private router: Router,
        private dbService: DBService,
        private dropDownService: DropDownService,
        private downloadService: DownLoadService,
        public dialog: MatDialog,
        // private ngxXml2jsonService: NgxXml2jsonService,
        private ref: ChangeDetectorRef,
        private loader: AppLoaderService,
        private storageService:StorageService) {
        this._sharedService.emitChange(this.pageTitle);
        // if(this.tables.length==0){
        //     this.openDialog()
        // }


        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.initialiseInvites();
            }
        });




    }


    initialiseInvites() {
        this.authService.getCurrentUser((err, user: User) => {
            this.currentUser = user;
            this.activatedroute.paramMap.subscribe((params) => {
                if (params.get('user') !== this.currentUser.username) {
                    return
                } else {
                    this.usr = params.get('user');
                    this.analysisId = params.get('id');
                }

            });
        });

    }

    ngOnInit() {
        this.pollForResult();

        this.dbService.singleSearch.subscribe(
            (data: SearchResult) => {


                if (Object.keys(data).length === 0) {
                    this.searchResultData = false;
                } else {
                    this.isFetching = false;
                }

                data && this.populateResultData(data);
            }
        );
        this.storageService.remove('xyz');

    }

    ToggleState(event) {
        this.displayTable = !this.displayTable;
   
    }
    ToggleState2(event) {
        this.displayObservation = !this.displayObservation;
    }

    ToggleState1(event) {
        this.displayPDFData = !this.displayPDFData;
        this.displayExPanels = !this.displayExPanels;
        this.displayTable = false;
    }

    openDialog() {
        this.loader.open(this.title);

    }

    closeDialog() {
       
        this.loader.close();
    }

    // ngAfterViewInit() {
    //     let li = this.data.nativeElement.value;
    
    // }
    showCharts() {
        this.showcharts = true;
    }

    pollForResult() {
        // tslint:disable-next-line: max-line-length
        // if(this.searchResult.analysisStatus === 'TableResult' || this.searchResult.analysisStatus === 'Complete' || this.searchResult.analysisStatus === 'Error'){
        //     this.closeDialog();
        // }
        // const callInterval = interval(10000);
        this.authService.getCurrentUser((err, user: User) => {
            this.signedInUser = user;
            if (!this.signedInUser || !this.signedInUser.signedIn) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.activatedroute.paramMap.subscribe((params) => {
                    if (params.get('user') !== this.signedInUser.username) {
                        this.illegalUser = true;
                      
                        return
                    } else {
                        this.usr = params.get('user');
                        this.analysisId = params.get('id');
                        this.dbService.getAnalysisDetails(this.usr, this.analysisId);
                        this.illegalUser = false;
                    }

                });
                if (!this.illegalUser) {
                    // this.callerSubscription = callInterval.subscribe(() => {
                    //     const status = this.searchResult?.analysisStatus;
                    //     
                    //     if ((status === 'TableResult' || status === 'Complete' || status === 'Error')) {
                    //         this.callerSubscription.unsubscribe();
                    //     } else {
                    this.dbService.getAnalysisDetails(this.signedInUser.username, this.analysisId);
                    //     }
                    // })
                }
            }
        });
        this.ref.detectChanges();





    }



    setStep(index: number) {
        this.step = index;

    }

    nextStep(table: Table, selection: boolean) {
        table.valid = selection;
        this.step++;

        for (const obj of this.tables) {
            if (obj.valid === true || obj.valid === false) {
                this.tableSelected = true;
            } else {
                this.tableSelected = false;
                break;
            }
        }
    }

    submitTableData() {
        this.tableProcessed = true;
        this.reviewTableSelection = false;
        this.dbService.submitTableData(this.tables, this.signedInUser.username, this.analysisId).subscribe((data: Result[]) => {
            this.pollForResult();
        });
    }

    getCount(result: Result, color: string) {
        let count = 0
        for (const resultItem of result.resultItem) {
            for (const items of resultItem.item) {
                if (items.color === color) {
                    count = count + items.message.length
                }
            }
        }
        return count;
    }

    getColor(level: string): string {
        if (level === 'Green') {
            return '#C5EEB2'
        } else if (level === 'Yellow') {
            return '#F8F8A9'
        } else if (level === 'Info') {
            return '#75cff8'
        } else if (level === 'Red') {
            return '#EEC2B7'
        }
    }


    processResult() {
 

        for (const result of this.results) {
            for (const resultItem of result.resultItem) {
                for (const item of resultItem.item) {
                    item.colorCode = this.getColor(item.color);
                }
            }
        }
        this.formType = this.searchResult.analysisType;
        try {
            this.output_graph = JSON.parse(this.searchResult.AnalysisResultGraph);
        } catch (error) {
        }
    }
    findKey(table) {
        //    this.arraytoJson(table)
        return table.hasOwnProperty('error')
    }

    
    populateResultData(data: SearchResult) {
        this.tablesList = [];
        this.searchResult = data;
        this.tableAnalysisType = this.searchResult.analysisSubtype;
        this.tablesList = this.searchResult.Tables;
        this.tablesNotFound = this.searchResult.MissingTables;
        const status = this.searchResult.analysisStatus;
        // TableSelected, NewAnalysis, Complete
        if (this.searchResult.analysisStatus === 'TableSelected' || this.searchResult.analysisStatus === 'Processed') {
            this.openDialog()
        }
        if (this.searchResult.analysisStatus === 'Complete' || this.searchResult.analysisStatus === 'Error') {
            this.closeDialog()
        }

        this.analysisDetails = JSON.parse(this.searchResult?.analysisDetails);
        if (status === 'TableResult') {

            if ((this.searchResult.Tables?.length > 4)) {

            }

        } else if (status === 'TableSelected') {
            this.selectedTables = this.searchResult.selectedTables;
            this.pollForResult()
        } else if (status === 'Complete') {
            if ((this.searchResult.Tables.length > 4)) {
            }
            this.results = JSON.parse(this.searchResult.analysisResult);
            this.processResult();

        } else if (status === 'Error') {
          
        }
    }


    tableFlag(text: string) {
        const flag = text.startsWith('<table') || text.startsWith('graph');
        return flag

    }

    getDisplayString(key) {
        return this.dropDownService.nameDescMap.get(key);
    }

    getSingedURL(path: string) {
        if (path.indexOf('https') !== -1) {
            return path;
        } else if (/\S/.test(path)) {
            return this.downloadService.getUrl(path);
        } else {
            return ' ';
        }

    }

    review() {
        const tableObjects: Table[] = JSON.parse(this.searchResult.selectedTables);
        const table_indexes: string[] = new Array();
        for (const tab of tableObjects) {
            table_indexes.push(tab.table_index)
        }

        for (const table of this.tables) {
            if (table_indexes.includes(table.table_index)) {
                table.valid = true;
            } else {
                table.valid = false;
            }
        }

        this.reviewTableSelection = true;
    }

    openGraph() {
        this.output_graph.forEach((element) => {
            const link = this.getSingedURL(element.key);
            const title = element.title;
            this.graphs.push(link);
        })
        // this.dialog.open(GraphDialogComponent, { data: { name: this.graphs }, disableClose: false, height: '540px', width: '82%' });

    }
    ngOnDestroy(): void {
        if (this.callerSubscription) {
            this.callerSubscription.unsubscribe();
        }
        if (this.mysubscription) {
            this.mysubscription.unsubscribe();
        }


    }

}
