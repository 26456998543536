import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import jsPDF from 'jspdf';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-qpcr-pdf-layout',
  templateUrl: './qpcr-pdf-layout.component.html',
  styleUrls: ['./qpcr-pdf-layout.component.scss']
})
export class QpcrPdfLayoutComponent implements OnInit {
  @ViewChild('htmlData', { static: false }) htmlData!: ElementRef;

  
  constructor() { }
  @Input()pdfData;
  tableData=[];
  tableSchema
  tableRowData

  ngOnInit(): void {
   
    this.tableData=this.pdfData.map(
      res=>res.table
    );
  this.tableSchema=this.tableData.map(
    res=>res.schema
  )
  this.tableRowData=this.tableData.map(
    res => res.data
  )
   
  }

  async exportPDF() {
    const doc = new jsPDF();
    
    const pdfTable = this.htmlData.nativeElement;
    let html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = {
      content: [
        // {
        //   image: this.logo,
        //   width: 150,
        //   style: 'logo',
        // },
        {
          text: ('User Inputs'),
          width: 100,
          alignment: 'center',
          margin: [0, 5, 10, 0],
        },
        html
      ],
      styles: {
        logo: {
          alignment: 'right',
        }
      }

    };
    pdfMake.createPdf(documentDefinition).download(`RedThreadOutput.pdf`)
  }


}
