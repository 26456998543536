import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DBService } from '../../../service/DB.service';
import { AuthNewService } from '../../../service/auth-new.service';

@Component({
  template: ``
})
export class UserSignOutComponent implements OnInit {

  constructor(private authService: AuthNewService, private dbService: DBService, private router: Router) { }

  ngOnInit(): void {
    // this.recordLogoutData();
    this.authService.signout();
    
   
  }

  recordLogoutData() {
   
    // sessionStorage.clear();
    // localStorage.clear();
    // this.dbService.recordAuthEvent('logout').subscribe((data: string) => {
    // });
  }
}
